const privilegeCategorizations = {
  system: [
    {
      label: 'Databases',
      privileges: [
        'ALTER ANY DATABASE',
        'BACKUP ANY DATABASE',
        'CREATE DATABASE',
        'DROP ANY DATABASE',
        'RESTORE ANY DATABASE'
      ]
    },
    {
      label: 'Roles',
      privileges: [
        'ALTER ANY ROLE',
        'DROP ANY ROLE',
        'CREATE ROLE',
        'VIEW ROLE'
      ]
    },
    {
      label: 'Session',
      privileges: [
        'CONTROL ANY SESSION',
        'EXPLAIN QUERY',
        'TRACE QUERY',
        'VIEW QUERY TEXT',
        'BULK LOAD'
      ]
    },
    {
      label: 'System',
      privileges: [
        'CONTROL CACHE',
        'CONTROL EXTERNAL AUTHENTICATION',
        'CONTROL LDAP',
        'CONTROL WLM'
      ]
    },
    {
      label: 'Cluster',
      privileges: [
        'ALTER ANY CLUSTER',
        'CREATE CLUSTER',
        'DROP ANY CLUSTER',
        'USAGE ANY CLUSTER'
      ]
    },
    {
      label: 'External Format',
      privileges: [
        'CREATE EXTERNAL FORMAT',
        'DROP ANY EXTERNAL FORMAT',
        'USAGE ANY EXTERNAL FORMAT'
      ]
    },
    {
      label: 'External Location',
      privileges: [
        'CREATE EXTERNAL LOCATION',
        'DROP ANY EXTERNAL LOCATION',
        'USAGE ANY EXTERNAL LOCATION'
      ]
    },
    {
      label: 'External Storage',
      privileges: [
        'CREATE EXTERNAL STORAGE',
        'DROP ANY EXTERNAL STORAGE',
        'USAGE ANY EXTERNAL STORAGE'
      ]
    }
  ],
  database: [
    {
      label: 'Schema',
      privileges: [
        'CREATE',
        'ALTER ANY SCHEMA',
        'DROP ANY SCHEMA'
      ]
    },
    {
      label: 'Session',
      privileges: [
        'CONNECT',
        'EXPLAIN QUERY',
        'TEMPORARY',
        'TRACE QUERY',
        'VIEW QUERY TEXT'
      ]
    },
    {
      label: 'Action',
      privileges: [
        'BACKUP',
        'RESTORE',
        'BULK LOAD'
      ]
    }
  ]
}

export default privilegeCategorizations
