<template>
  <yb-drop-down-button
    :show-arrow="showArrow"
    :align-right="true"
    class="role-selector"
    :class="showBorder ? 'border dark:bg-yb-gray-alt-dark dark:border-yb-gray-alt-lightest-inverse dark:hover:border-yb-brand-primary border-yb-gray-lighter rounded-md' : ''"
    @open="withGrantOption = false"
  >
    <template #label>
      <yb-icon :icon="icon || 'plus-circle'" class="h-4 w-4" />
      <div v-if="!!label" class="ml-1 font-light inline-block">
        {{ label }}
      </div>
    </template>

    <div v-if="showWithGrantOption || showGrantAllOption" class="flex flex-col space-y-2 py-2 border-b yb-border-dialog  mb-1" @click.stop="">
      <yb-input
        v-if="showWithGrantOption"
        v-model="withGrantOption"
        type="checkbox"
        label="Grant can be shared"
        icon="share"
        help="Allow grant of this privilege to other user/roles (WITH GRANT OPTION)"
        class="mx-4 text-sm whitespace-nowrap"
        width-class="w-auto"
      />

      <yb-input
        v-if="showGrantAllOption"
        v-model="grantAllOption"
        type="checkbox"
        label="Grant ALL"
        icon="share"
        help="Grant ALL privileges to this user/role"
        class="mx-4 text-sm whitespace-nowrap"
        width-class="w-auto"
      />
    </div>

    <yb-drop-down-item
      v-for="r in sortedRoles"
      :key="r.id"
      :icon="r.type || 'user'"
      @click.prevent="$emit('add', r, withGrantOption, !showGrantAllOption || grantAllOption)"
    >
      <div class="flex flex-row flex-nowrap items-center w-full">
        {{ r.name }}
      </div>
    </yb-drop-down-item>
  </yb-drop-down-button>
</template>

<script>
import * as functions from '@/util/functions'

export default {
  props: {
    roles: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    showWithGrantOption: {
      type: Boolean,
      required: false,
      default: false
    },
    showGrantAllOption: {
      type: Boolean,
      required: false,
      default: false
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      withGrantOption: false,
      grantAllOption: false
    }
  },
  computed: {
    sortedRoles() {
      return this.roles.sort(functions.sortByName)
    }
  }
}
</script>

<style lang="postcss" scoped>
div.role-selector :deep(.yb-button:hover) {
  @apply !bg-transparent;
}
</style>
