<template>
  <div class="relative bg-white dark:bg-yb-gray-alt-dark border rounded-lg text-base font-light py-0 px-1.5 h-7 flex items-center select-none" :class="'border-yb-' + color">
    <div v-if="false" class="border-yb-brand-primary bg-yb-brand-primary" />
    <div v-if="false" class="border-yb-lava bg-yb-lava" />
    <yb-icon v-if="icon" :icon="icon" class="yb-button-icon" />
    <div class="text-sm">
      {{ label }}
    </div>
    <div
      v-if="editable && shareable"
      v-tooltip="{content: (shared ? 'Revoke' : 'Allow') + ' granting this privilege to other users/roles (WITH GRANT OPTION)', placement: 'right', delay: 1000}"
      class="flex flex-row items-center border-l border-r h-full pl-1.5 pr-1 ml-2 cursor-pointer bg-opacity-20 dark:bg-opacity-50"
      :class="shared ? 'bg-yb-' + color + ' border-yb-' + color: 'bg-yb-gray-faint dark:bg-yb-gray-medium text-yb-gray-light' + ' border-yb-' + color"
      @click="editable && $emit('share', !shared)"
    >
      <yb-icon icon="share" class="yb-button-icon" />
    </div>
    <div
      v-if="editable && removable"
      v-tooltip="{content: 'Remove this role/privilege (REVOKE)', placement: 'right', delay: 1000}"
      class=" rounded-full p-1 ml-2 cursor-pointer"
      :class="'bg-yb-' + color"
      @click="editable && $emit('remove')"
    >
      <yb-svg-close class="text-white dark:text-yb-gray-alt-dark w-2 h-2 fill-current" />
    </div>
  </div>
</template>

<script>
import YbSvgClose from '@/assets/svg/close.svg'

export default {
  components: {
    YbSvgClose
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    label: {
      type: [String, Number],
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    },
    removable: {
      type: Boolean,
      required: false,
      default: false
    },
    remove: {
      type: Boolean,
      required: false,
      default: false
    },
    shareable: {
      type: Boolean,
      required: false,
      default: false
    },
    shared: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    color() {
      return !this.remove ? 'brand-primary' : 'lava'
    }
  }
}
</script>
